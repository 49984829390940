<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-xl>
            <v-layout wrap>
                <v-flex xs12>
                    <v-switch v-model="item.enable" :label="$t('Habilitado')" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field inputmode="numeric" v-model="item.zipCodeStart" :rules="[rules.required]" :label="$t('CEP Inicio')" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field inputmode="numeric" v-model="item.zipCodeStop" :rules="[rules.required]" :label="$t('CEP Fim')" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Região')" v-model="item.region" :rules="[rules.required]" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Bairro')" v-model="item.district" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Cidade')" v-model="item.city" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Endereço')" v-model="item.address" />
                </v-flex>

                <v-flex xs12 md12 sm12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">Salvar</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            itemClean: {
                name: "",
                enable: true,
            },
            item: { ...this.itemClean },
            stores: null,
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>